<template>
    <div class="content-wrapper">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="content">
            <Markers stage2></Markers>

            <component :is="activeComponent" v-if="activeComponent" class="mt-12"></component>

            <h2 class="dashboard mt-12" v-else>
                В настоящее время авторизация в этом источнике не поддерживается
            </h2>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//TODO рефакторинг, слишком много коннекторов
import { mapState } from 'vuex'
import Markers from '@/components/addConnector/Markers.vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import AlfaAuth from '@/components/connectors/alfa/Auth.vue'
import PlanfactAuth from '@/components/connectors/planfact/Auth.vue'
import YClientsAuth from '@/components/connectors/yClients/Auth.vue'
import YClientsSimpleAuth from '@/components/connectors/yClients/AuthSimple.vue'
import ZenmoneyAuth from '@/components/connectors/zenmoney/Auth.vue'
import UniverseSoftAuth from '@/components/connectors/universeSoft/Auth.vue'
import ProfSalonAuth from '@/components/connectors/profSalon/Auth.vue'
import SycretAuth from '@/components/connectors/sycret/Auth.vue'
import SonlineAuth from '@/components/connectors/sonline/Auth.vue'
import ArnicaAuth from '@/components/connectors/arnica/Auth.vue'
import MoiSkladAuth from '@/components/connectors/moiSklad/Auth.vue'
import Helix1CAuth from '@/components/connectors/helix1C/Auth.vue'
import lenaLeninaAuth from '@/components/connectors/lenaLenina/Auth.vue'
import WhatsappAuth from '@/components/connectors/whatsapp/Auth.vue'
import AltegioAuth from '@/components/connectors/altegio/Auth.vue'
import ProfSalonBatAuth from '@/components/connectors/profSalonBat/Auth.vue'
import DikidiAuth from '@/components/connectors/dikidi/Auth.vue'
import ArchimedAuth from '../components/connectors/archimed/Auth.vue'
import AmoCrmAuth from '../components/connectors/amoCrm/Auth.vue'
import AmoCrmFullAuth from '../components/connectors/amoCrmFull/Auth.vue'
import TelephonyMegaphoneAuth from '@/components/connectors/telephonyMegaphone/Auth.vue'
import TelephonyProstyiZvonkiAuth from '@/components/connectors/telephonyProstyiZvonki/Auth.vue'
import ruslan1CAuth from '@/components/connectors/ruslan1C/Auth.vue'
import dentalProAuth from '@/components/connectors/dentalPro/Auth.vue'
import KlientixAuth from '@/components/connectors/klientix/Auth.vue'
import SipuniAuth from '@/components/connectors/sipuni/Auth.vue'
import Cloud1CAuth from '@/components/connectors/cloud1C/Auth.vue'
import WhatsappBasisAuth from '@/components/connectors/whatsappBasis/Auth.vue'
import ArnicaV2Auth from '../components/connectors/arnicaV2/ArnicaV2.vue'
import TelephonyBeeline from '@/components/connectors/telephonyBeeline/Auth.vue'
import telephonyMango from '@/components/connectors/telephonyMango/Auth.vue'
import telephonyPbx from '@/components/connectors/telephonyPbx/Auth.vue'
import prostoSMS from '@/components/connectors/prostoSMS/Auth.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import cfg from '@root/config'

export default {
    name: 'AddConnectorStage3',
    components: {
        Markers,
        Breadcrumbs,
        AlfaAuth,
        PlanfactAuth,
        YClientsAuth,
        YClientsSimpleAuth,
        ZenmoneyAuth,
        UniverseSoftAuth,
        ProfSalonAuth,
        SycretAuth,
        SonlineAuth,
        ArnicaAuth,
        MoiSkladAuth,
        Helix1CAuth,
        WhatsappAuth,
        lenaLeninaAuth,
        AltegioAuth,
        ProfSalonBatAuth,
        TelephonyMegaphoneAuth,
        telephonyPbx,
    },
    data: () => ({
        CONNECTOR_TYPES,
        yCSimpleAuth: cfg.yClientSimpleAuth, //Включена ли упрощенная авторизация в yClinents
        views: [
            { connectorType: CONNECTOR_TYPES.alfa, component: AlfaAuth },
            { connectorType: CONNECTOR_TYPES.yClients, component: YClientsSimpleAuth },
            { connectorType: CONNECTOR_TYPES.zenmoney, component: ZenmoneyAuth },
            { connectorType: CONNECTOR_TYPES.universeSoft, component: UniverseSoftAuth },
            { connectorType: CONNECTOR_TYPES.sycret, component: SycretAuth },
            { connectorType: CONNECTOR_TYPES.profSalonBat, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.profSalonBatV2, component: ProfSalonBatAuth },
            { connectorType: CONNECTOR_TYPES.sonline, component: SonlineAuth },
            { connectorType: CONNECTOR_TYPES.arnica, component: ArnicaAuth },
            { connectorType: CONNECTOR_TYPES.moiSklad, component: MoiSkladAuth },
            { connectorType: CONNECTOR_TYPES.helix1C, component: Helix1CAuth },
            { connectorType: CONNECTOR_TYPES.whatsapp, component: WhatsappAuth },
            { connectorType: CONNECTOR_TYPES.lenaLenina, component: lenaLeninaAuth },
            { connectorType: CONNECTOR_TYPES.altegio, component: AltegioAuth },
            { connectorType: CONNECTOR_TYPES.dikidi, component: DikidiAuth },
            { connectorType: CONNECTOR_TYPES.archimed, component: ArchimedAuth },
            { connectorType: CONNECTOR_TYPES.amoCrm, component: AmoCrmAuth },
            { connectorType: CONNECTOR_TYPES.telephonyMegaphone, component: TelephonyMegaphoneAuth },
            { connectorType: CONNECTOR_TYPES.telephonyProstyiZvonki, component: TelephonyProstyiZvonkiAuth },
            { connectorType: CONNECTOR_TYPES.ruslan1C, component: ruslan1CAuth },
            { connectorType: CONNECTOR_TYPES.cloud1C, component: Cloud1CAuth },
            { connectorType: CONNECTOR_TYPES.dentalPro, component: dentalProAuth },
            { connectorType: CONNECTOR_TYPES.amoCrmFull, component: AmoCrmFullAuth },
            { connectorType: CONNECTOR_TYPES.klientix, component: KlientixAuth },
            { connectorType: CONNECTOR_TYPES.sipuni, component: SipuniAuth },
            { connectorType: CONNECTOR_TYPES.whatsappBasis, component: WhatsappBasisAuth },
            { connectorType: CONNECTOR_TYPES.arnicaV2, component: ArnicaV2Auth },
            { connectorType: CONNECTOR_TYPES.telephonyBeeline, component: TelephonyBeeline },
            { connectorType: CONNECTOR_TYPES.telephonyMango, component: telephonyMango },
            { connectorType: CONNECTOR_TYPES.pbxOnline, component: telephonyPbx },
            { connectorType: CONNECTOR_TYPES.prostoSMS, component: prostoSMS },
        ],
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector', 'loggedAddingConnector']),
        currentAddingConnectorType() {
            return this.currentAddingConnector.type
        },
        activeComponent() {
            return this.views.find(el => el.connectorType === this.currentAddingConnectorType)?.component
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа для Idol Face',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'ДОБАВИТЬ ИСТОЧНИК',
                    disabled: true,
                    to: '',
                },
            ]
        },
    },

    methods: {
        next() {
            const path = this.$router.currentRoute.path
                .split('/')
                .slice(0, 3)
                .join('/')
            this.$router.push(`${path}/addConnectorStage3`)
        },
        async login() {
            const res = await this.$store.dispatch('project/addConnector', this.loggedAddingConnector)
            if (res.error) {
                this.$store.dispatch('callNotify', 'Ошибка')
                return
            }
            this.next()
        },
    },
    async created() {
        if (this.$router.currentRoute.query.fromStage == 3) {
            await this.$store.dispatch('project/deleteConnector', this.loggedAddingConnector.connectorId)
        }
        this.$on('login', this.login)
    },
}
</script>
<style lang="sass" scoped>
.addConnector
    padding-top: 5%
    padding-left: 10%
    padding-right: 10%
</style>
